// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-calculatedate-js": () => import("./../../../src/pages/contact/calculatedate.js" /* webpackChunkName: "component---src-pages-contact-calculatedate-js" */),
  "component---src-pages-contact-callback-js": () => import("./../../../src/pages/contact/callback.js" /* webpackChunkName: "component---src-pages-contact-callback-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-reservation-js": () => import("./../../../src/pages/contact/reservation.js" /* webpackChunkName: "component---src-pages-contact-reservation-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-baby-js": () => import("./../../../src/pages/portfolio/baby.js" /* webpackChunkName: "component---src-pages-portfolio-baby-js" */),
  "component---src-pages-portfolio-belly-js": () => import("./../../../src/pages/portfolio/belly.js" /* webpackChunkName: "component---src-pages-portfolio-belly-js" */),
  "component---src-pages-portfolio-newborn-js": () => import("./../../../src/pages/portfolio/newborn.js" /* webpackChunkName: "component---src-pages-portfolio-newborn-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

